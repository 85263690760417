import { withPageAuthRequired } from "@auth0/nextjs-auth0";
import { captureException } from "@sentry/nextjs";
import Cookies from "js-cookie";
import { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { AlertsSection } from "~/components/dashboard/cards/AlertsCard/AlertsSection";
import GoalsCards from "~/components/dashboard/cards/goals/GoalsCards";
import { MoCrclBanner } from "~/components/dashboard/MoCrclBanner";
import { QRCodeBanner } from "~/components/dashboard/QrCodeBanner";
import TaskCards from "~/components/dashboard/tasks/TaskCards";
import { TracksBanner } from "~/components/dashboard/TracksBanner";
import { useFarmerProfile } from "~/components/hooks/useFarmerProfile";
import { useUser } from "~/components/hooks/useUser";
import DashboardLayout from "~/components/layout/dashboard/DashboardLayout";
import VideoModal from "~/components/modal/modals/VideoModal";
import LoadingView from "~/components/views/LoadingView";
import { logout } from "~/lib/utils/auth";

const DashboardPage: NextPage = () => {
  const user = useUser();
  const farmerProfile = useFarmerProfile();
  const router = useRouter();
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);

  const [videoWatched] = useState<boolean>(
    Cookies.get("dashboard-video") == "watched"
  );
  const isLoading = user.isLoading || farmerProfile.isLoading;

  if (isLoading) {
    return <LoadingView loadingText="Getting your user information..." />;
  }

  if (!user.data) {
    if (user.isError) {
      throw user.error;
    }
    captureException(
      Error(
        `Null user in dashboard page. User status: isFetching: ${user.isFetching}, isRefetching: ${user.isRefetching}, isError: ${user.isError}, isSuccess: ${user.isSuccess}. User was logged out.`
      )
    );
    logout(router);
    return null;
  }

  function onBannerClicked() {
    if (screen.width < 650) {
      // the video modal stops fitting here
      window.open("https://youtu.be/CCAtBCING90", "_blank");
      Cookies.set("dashboard-video", "watched");
    } else {
      setShowVideoModal(true);
    }
  }

  return (
    <>
      <Head>
        <title>Dashboard | FarmRaise</title>
      </Head>

      {showVideoModal && (
        <VideoModal
          onClose={() => {
            setShowVideoModal(false);
            Cookies.set("dashboard-video", "watched");
          }}
        ></VideoModal>
      )}

      <DashboardLayout>
        <div className="px-4 sm:px-0">
          <div className="flex flex-col justify-between gap-6">
            <div className="flex flex-col justify-between gap-6 lg:flex-row">
              <div className="space-y-2 lg:flex-1">
                <div className="pb-6 text-3xl font-medium">
                  Hi, {user.data.firstName}
                </div>
                {user.data.partners?.includes("MO_CRCL") && <MoCrclBanner />}
                {user.data.isMember && (
                  <div className="md:hidden">
                    <TracksBanner />
                  </div>
                )}
                {user.data.isMember && (
                  <div className="hidden md:block">
                    <QRCodeBanner />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-between gap-6 lg:flex-row">
              <Image
                src="/images/StartHereBanner.png"
                className="cursor-pointer"
                width={856 * 0.65}
                height={478 * 0.65}
                alt="Dashboard Intro Video Tour: A step-by-step guide to making the most of your FarmRaise subscription"
                onClick={onBannerClicked}
              />
              <a
                href="https://form.typeform.com/to/Yl8RWIeU"
                target="_blank"
                rel="noreferrer"
                className="contents"
              >
                <Image
                  src="/images/FundingOpportunityQuiz.png"
                  width={928 * 0.65}
                  height={478 * 0.65}
                  className="ml-auto mr-0"
                  alt="Funding Opportunity Quiz"
                />
              </a>
            </div>
          </div>

          <div className="z-10 space-y-12 pt-6 lg:pt-20">
            <GoalsCards></GoalsCards>
            <div className="space-y-2">
              <h2>Funding Alerts</h2>
              <AlertsSection
                user={user.data}
                farmerProfile={farmerProfile.data}
              />
            </div>
            <TaskCards />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default withPageAuthRequired(DashboardPage);
