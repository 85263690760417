import { FC } from "react";
import { MizzouLogoOnly } from "../partnerApplications/moCrcl/assets/mizzou_logo_only";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";

export const MoCrclBanner: FC = () => {
  const router = useRouter();
  return (
    <button
      onClick={() => router.push(`/mo-crcl`)}
      className="cursor-pointer flex justify-between items-center rounded-md bg-gradient-to-r from-[#5E8317] to-[#79AC14] text-white py-3 px-6 w-full"
      aria-label="Go to my MO CRCL Dashboard"
    >
      <div className="flex items-center space-x-6">
        <MizzouLogoOnly />
        <span>Looking for the Missouri CRCL Project?</span>
      </div>

      <div className="flex items-center space-x-3">
        <ArrowRightIcon className="h-6 w-6" />
        <span>Go to my MO CRCL Dashboard</span>
      </div>
    </button>
  );
};
